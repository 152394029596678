<template>
  <v-app>
    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      overlay: true,
      expandOnHover: false,
    }),
  }
</script>
<style>
  .v-overlay__content {
    width: 100vw;
    height: 100vh;
  }
  #overlay-section {
    margin: 3% 5%;
  }
  #overlay-bottom-section {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
</style>
